<script>
export default {
  form: {
    custom_code: {
      label: 'Code HTML',
      type: 'textarea',
      column: 'is-12',
    },
  },
  data() {
    return {
      isLoading: false,
      store: {
        custom_code: '',
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  created() {
    Object.keys(this.store)
      .forEach((k) => (this.store[k] = this.authStore[k]));
  },
  methods: {
    handle() {
      this.isLoading = true;
      this.$store.dispatch('auth/updateStore', { ...this.store })
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Messagerie instantanée <AppFeatureBadge class="ml-3" position="is-right" />
    </template>
    <template #content>
      <p>
        Ajoutez une application ou un outil externe sur les pages de l'espace apprenant
        grâce à du code HTML (<strong>Facebook messenger</strong>,
        <strong>Crisp</strong>...).
      </p>
      <p class="has-text-weight-bold">
        Ce code HTML sera chargé en pied de page, dans toutes les pages de votre site
        (sauf en intégration externe).
        <span class="has-text-danger">
          À utiliser avec précaution !
        </span>
      </p>
      <h3 class="is-size-6">
        Utilisez Crisp pour gérer votre relation client !
      </h3>
      <p>
        <a href="https://crisp.chat/?track=3kOwgOHfLu" target="_blank" rel="noopener noreferrer">
          Crisp
        </a>
        est un outil de <strong>messagerie instantanée</strong> qui permettra
        à vos apprenants de vous contacter directement. C'est d'ailleurs l'outil
        que nous utilisons pour communiquer avec vous !
        <strong>Oui, regardez en bas à droite :)</strong>
      </p>
      <p>
        <a href="https://crisp.chat/?track=3kOwgOHfLu" target="_blank" rel="noopener noreferrer">Crisp</a>
        propose une formule gratuite, mais si vous optez pour une formule payante,
        sachez que vous avez droit à <strong>20% de réduction
        grâce à Teachizy</strong> !
        <br>
        Code promo à utiliser : <strong>TEACHIZY+20%+BMV6IE</strong>
      </p>
    </template>

    <template #form>
      <GoProWrapper>
        <form novalidate class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
          <div class="columns is-multiline">
            <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
              <BaseField v-model="store[k]" :field="field" />
            </div>
          </div>

          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </GoProWrapper>
    </template>
  </AppFeatureWrapper>
</template>
