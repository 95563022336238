<script>
export default {
  form: {
    analytics_code: {
      label: 'Code Google Analytics',
      type: 'text',
      column: 'is-12',
      inputAttrs: {
        placeholder: 'XXXXXXXXXX',
      },
    },
    pixel_code: {
      label: 'Code Facebook Pixel',
      type: 'text',
      column: 'is-12',
      inputAttrs: {
        placeholder: 'XXXXXXXXXX',
      },
    },
  },
  data() {
    return {
      isLoading: false,
      store: {
        pixel_code: '',
        analytics_code: '',
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  created() {
    Object.keys(this.store)
      .forEach((k) => (this.store[k] = this.authStore[k]));
  },
  methods: {
    handle() {
      this.isLoading = true;
      this.$store.dispatch('auth/updateStore', { ...this.store })
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div class="columns">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Outils de suivi
      </h2>
      <p>
        Indiquez ici vos codes <strong>Google Analytics</strong> ou <strong>Facebook Pixel</strong>
        pour suivre vos performances et piloter vos campagnes.
      </p>
    </div>

    <div class="column">
      <form novalidate class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <div class="columns is-multiline">
          <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
            <BaseField v-model="store[k]" :field="field" />
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>
